/**
 * External dependencies.
 */
import type { GetStaticProps, NextPage } from "next";

/**
 * Internal dependencies.
 */
import Layout from "../layout/layout";
import { fetchAPI, loadTranslation, getLocale, payloadPopulate } from "utils";
import SchemaSet from "components/schema-set/schema-set";
import SEO from "components/seo/seo";
import DynamicSection from "blocks/dynamic-section/dynamic-section";

const Home = ({ data }: any) => {
  const {
    Sections,
    SEO: seoData,
    DarkHeader,
    DarkContent,
    FooterAlt,
  }: any = data?.attributes ?? {};

  return (
    <Layout
      title="DigiFist"
      desc="DigiFist"
      light={!DarkHeader}
      dark={DarkContent}
      FooterAlt={FooterAlt}
      notice
    >
      <SEO data={seoData} />
      <SchemaSet
        schemaData={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "Homepage",
        }}
      />
      {Sections && <DynamicSection data={Sections} removeClass />}
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const translation = await loadTranslation(
    ctx.locale!,
    process.env.NODE_ENV === "production"
  );
  const locale = getLocale(ctx.locale);

  let globalAttrs = {
    Home: null,
  };

  const globalResponse = await fetchAPI("/global", {
    populate: {
      HeaderLeftMenu: "*",
      HeaderRightMenu: "*",
      Favico: "*",
      FooterLinks: {
        populate: "*",
      },
      SocialLinks: "*",
      BottomLinks: "*",
      Home: {
        populate: "*",
      },
      page: {
        populate: "*",
      },
      Slide: {
        populate: "*",
      },
      localizations: "*",
    },
    locale: locale,
  });

  if (globalResponse?.data?.attributes) {
    globalAttrs = globalResponse?.data?.attributes;
  }

  if (!globalAttrs.Home) {
    return {
      props: {
        data: {},
        locale: ctx.locale,
      },
    };
  }

  const {
    Home: { page: { data: homeData } = { data: {} } },
  } = globalAttrs;

  if (!homeData) {
    return {
      notFound: true,
    };
  }
  const { id: homeID }: any = homeData;

  const { data } = await fetchAPI(`/pages/${homeID}`, {
    populate: payloadPopulate,
    locale: locale,
  });

  return {
    props: {
      translation,
      data,
    },
    revalidate: 10,
  };
};

export default Home;
